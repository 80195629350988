import {
  CircularProgress,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { notifyError, notifysuccess } from "../../../Notification";
import { useUpdateAppointmentMutation } from "../../../services/appointmentsAPI";
import { useGetDoctorsQuery } from "../../../services/doctorAPI";
import { useGetPatientsQuery } from "../../../services/patientsAPI";

function UpdateAppointment({ type, onClose, data }) {
  const [t, i18n] = useTranslation();

  // Replace Redux selectors with RTK Query hooks
  const {
    data: doctors,
    isLoading: loadingDoctors,
    error: ErrorFetchDoctor,
  } = useGetDoctorsQuery({
    lang: i18n.language,
  });

  const {
    data: users,
    isLoading: loadingUsers,
    error: ErrorFetchUsers,
  } = useGetPatientsQuery({
    lang: i18n.language,
  });

  const [updateAppointment, { isLoading: loadingBTN }] =
    useUpdateAppointmentMutation();

  const [inp, setInp] = useState({
    company_id: data.company_id,
  });

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  const onsubmitfn = async (e) => {
    e.preventDefault();

    try {
      const newdata = {
        ...inp,
        _method: "PUT",
      };

      const result = await updateAppointment({
        id: data.id,
        data: newdata,
        lang: i18n.language,
      }).unwrap();

      if (result.success) {
        notifysuccess(result.message);
        onClose();
      } else {
        notifyError("Failed to update appointment. Please try again.");
      }
    } catch (error) {
      notifyError(error?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <DialogTitle>
        <Typography
          variant="h6"
          gutterBottom
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {type === "edit"
            ? t("Edit Appoinetment")
            : type === "view"
            ? t("View Appoinetment")
            : t("Add Appoinetment")}
        </Typography>
      </DialogTitle>
      <>
        {ErrorFetchDoctor || ErrorFetchUsers ? (
          <ErrorCompo />
        ) : loadingDoctors || loadingUsers ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-lg-12">
              <div className="form-group mt-2">
                <FormControl fullWidth required>
                  <InputLabel id="Doctor">{t("Doctor")}</InputLabel>
                  <Select
                    labelId="Doctor"
                    name="doctor_id"
                    id="Doctor"
                    required
                    label="Doctor"
                    defaultValue={data.doctor_id}
                    onChange={(e) => getValue(e)}
                  >
                    {loadingDoctors ? (
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      doctors?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group mt-2">
                <FormControl fullWidth required>
                  <InputLabel id="Patient">{t("Patient")}</InputLabel>
                  <Select
                    labelId="Patient"
                    name="patient_id"
                    id="Patient"
                    required
                    label="Patient"
                    onChange={(e) => getValue(e)}
                    defaultValue={data.patient_id}
                  >
                    {loadingUsers ? (
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      users?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "10px",
                  paddingLeft: "7px",
                }}
              >
                <p>
                  {t("Active")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  defaultChecked={true}
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span onClick={onClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </>
    </>
  );
}

export default UpdateAppointment;
