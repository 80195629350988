import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { DialogTitle, InputLabel, TextField, Typography } from "@mui/material";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { notifyError } from "../../../Notification";
import {
  useCreateTransactionMutation,
  useGetAppointmentByIdQuery,
} from "../../../services/appointmentsAPI";

function AddTransaction({ type, onClose, data }) {
  const [t, i18n] = useTranslation();

  // RTK Query hooks
  const [createTransaction, { isLoading: loadingBTN }] =
    useCreateTransactionMutation();
  const { refetch: refetchAppointment } = useGetAppointmentByIdQuery(
    { id: data.IdAppo, lang: i18n.language },
    { skip: !data.IdAppo }
  );

  const [inp, setInp] = useState({
    appointment_payment_id: data.paymentID,
  });

  const handleChangeDate = (e) => {
    setInp((prev) => ({
      ...prev,
      selected_datetime: e.format("YYYY-MM-DD"),
    }));
  };

  const getValue = (e) => {
    setInp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onsubmitfn = async (e) => {
    e.preventDefault();

    try {
      const result = await createTransaction({
        data: inp,
        lang: i18n.language,
      }).unwrap();

      if (result.success) {
        await refetchAppointment();
        onClose();
      }
    } catch (error) {
      notifyError(
        error?.data?.message || "Failed to add Transaction. Please try again."
      );
    }
  };

  return (
    <>
      <DialogTitle>
        <Typography
          variant="h6"
          gutterBottom
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {type === "edit"
            ? t("Edit Transaction")
            : type === "view"
            ? t("View Transaction")
            : t("Add Transaction")}
        </Typography>
      </DialogTitle>
      <>
        <form className="row" onSubmit={onsubmitfn}>
          <div className="col-lg-12">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel id="Date">{t("Date")}:</InputLabel>
              <DatePicker
                onChange={(newValue) => handleChangeDate(newValue)}
                defaultValue={null}
              />
            </LocalizationProvider>
          </div>

          <div className="col-lg-12">
            <div className="form-group mb-4 mt-4">
              <TextField
                id={"text"}
                type={"text"}
                label={t("Cost")}
                name={"cost"}
                onChange={getValue}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div className="btn-lest mt-3">
            {loadingBTN ? (
              <button type="text" disabled className="btn btn-primary-rgba">
                <CheckCircleIcon color="#fff" /> {t("Loading")}...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary-rgba">
                <CheckCircleIcon color="#fff" />
                {type === "edit" ? t("Edit") : t("Create")}
              </button>
            )}

            <span onClick={onClose} className="btn btn-danger-rgba">
              <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
            </span>
          </div>
        </form>
      </>
    </>
  );
}

export default AddTransaction;
