import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import { useGetPatientByIdQuery } from "../../services/patientsAPI";
import "./PopUpUser.scss";

function PopUpUser({ id }) {
  const [t, i18n] = useTranslation();
  const {
    data: patient,
    isLoading,
    error,
  } = useGetPatientByIdQuery({ id, lang: i18n.language });

  if (error) return <ErrorCompo />;
  if (isLoading) return <CircularProgress />;

  return (
    <div className="popup-user">
      <div className="popup-header">
        <div className="user-avatar">
          {patient?.image ? (
            <img
              src={`${process.env.BASE_URL}/storage/${patient.image}`}
              alt={patient?.name}
            />
          ) : (
            <div className="avatar-placeholder">{patient?.name?.charAt(0)}</div>
          )}
        </div>
        <h2>{patient?.name}</h2>
      </div>

      <div className="popup-content">
        <div className="info-grid">
          <InfoItem label={t("Email")} value={patient?.email} />
          <InfoItem label={t("Phone Number")} value={patient?.phone_number} />
          <InfoItem label={t("Gender")} value={patient?.gender} />
          <InfoItem
            label={t("Date Of Birth")}
            value={patient?.date_of_birthday}
          />
          <InfoItem label={t("Address")} value={patient?.address} />

          <InfoItem
            label={t("Sensitive Status")}
            value={patient?.sensitive ? t(patient.sensitive) : t("No")}
          />

          {patient?.sensitive_note && (
            <InfoItem
              label={t("Sensitive Note")}
              value={patient?.sensitive_note}
              fullWidth
            />
          )}

          <InfoItem
            label={t("Chronic Disease")}
            value={
              patient?.chronic_disease?.translations?.find(
                (t) => t.locale === i18n.language
              )?.name || patient?.chronic_disease?.name
            }
          />

          <InfoItem
            label={t("Language Speaks")}
            value={
              patient?.patients_language_speaks?.[0]?.translations?.find(
                (t) => t.locale === i18n.language
              )?.name || patient?.patients_language_speaks?.[0]?.name
            }
          />

          <InfoItem
            label={t("Access Method")}
            value={
              patient?.access_method?.translations?.find(
                (t) => t.locale === i18n.language
              )?.name || patient?.access_method?.name
            }
          />
        </div>
      </div>
    </div>
  );
}

// Helper component for displaying info items
const InfoItem = ({ label, value, fullWidth }) => (
  <div className={`info-item ${fullWidth ? "full-width" : ""}`}>
    <span className="label">{label}:</span>
    <span className="value">{value || "-"}</span>
  </div>
);

export default PopUpUser;
