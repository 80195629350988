import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const doctorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all doctors
    getDoctors: builder.query({
      query: (arg) => ({
        url: "/cors/doctors",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.doctors.data;
      },
      providesTags: ["Doctors"],
    }),

    // Get single doctor
    getDoctorById: builder.query({
      query: (arg) => ({
        url: `/cors/doctors/${arg.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.item;
      },
      providesTags: (result, error, arg) => [{ type: "Doctor", id: arg.id }],
    }),

    // Create doctor
    createDoctor: builder.mutation({
      query: (arg) => ({
        url: "/cors/doctors",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (error) => {
        return error;
      },
      invalidatesTags: ["Doctors"],
    }),

    // Update doctor
    updateDoctor: builder.mutation({
      query: (arg) => ({
        url: `/cors/doctors/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (error) => {
        return error;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Doctor", id: arg.id },
        "Doctors",
      ],
    }),

    // Delete doctor
    deleteDoctor: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/cors/doctors/${id}`,
              method: "DELETE",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Doctor Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Doctors"],
    }),
  }),
});

export const {
  useGetDoctorsQuery,
  useGetDoctorByIdQuery,
  useCreateDoctorMutation,
  useUpdateDoctorMutation,
  useDeleteDoctorMutation,
} = doctorsApi;
