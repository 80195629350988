import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const patientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all patients
    getPatients: builder.query({
      query: (arg) => ({
        url: "/cors/patients",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.patients.data;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      providesTags: ["Patients"],
    }),

    // Get single patient
    getPatientById: builder.query({
      query: (arg) => ({
        url: `/cors/patients/${arg.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.item;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      providesTags: (result, error, arg) => [{ type: "Patient", id: arg.id }],
    }),

    // Create patient
    createPatient: builder.mutation({
      query: (arg) => ({
        url: "/cors/patients",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        notifysuccess(response.message);
        return response;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      invalidatesTags: ["Patients"],
    }),

    // Update patient
    updatePatient: builder.mutation({
      query: (arg) => ({
        url: `/cors/patients/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        notifysuccess(response.message);
        return response;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Patient", id: arg.id },
        "Patients",
        "Patient",
      ],
    }),

    // Delete patient
    deletePatient: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/cors/patients/${id}`,
              method: "DELETE", // Changed to DELETE to match doctors
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Patient Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Patients"],
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useGetPatientByIdQuery,
  useCreatePatientMutation,
  useUpdatePatientMutation,
  useDeletePatientMutation,
} = patientsApi;
