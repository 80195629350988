import "./login.scss";
import img1 from "../../assits/logo_app.svg";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { islogged, logdata } from "../../store/authslice";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { notifyError } from "../../Notification";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

function Login({ path }) {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // State management
  const [recap, setReCap] = useState("");
  const [inp, setinp] = useState({
    email: "",
    password: "",
  });
  const [loadingSub, setLoadingSub] = useState(false);
  const [isValid, setIsValid] = useState(false);

  // Redux state
  const { data, loading, error } = useSelector(
    (state) => state.persistData.auth
  );

  // Form validation effect
  useEffect(() => {
    const isFormValid = inp.email && inp.password;
    setIsValid(isFormValid);
  }, [inp, recap]);

  // Redirect if already logged in
  useEffect(() => {
    if (data) {
      navigate("/");
    }
  }, [data, navigate]);

  // Input handler
  const getdata = (e) => {
    setinp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Form submission handler
  const getvalue = async (e) => {
    e.preventDefault();

    if (!recap) {
      return notifyError(
        "Please click on the verification option to make sure you are not a robot"
      );
    }

    if (!isValid) return;

    setLoadingSub(true);
    try {
      const result = await dispatch(logdata(inp)).unwrap();
      if (result?.token) {
        Cookies.set("token", result.token, { expires: 1 });
        if (location.state?.data) {
          navigate(`/course/${location.state.data}`);
        } else {
          navigate(path === "/login" ? "/" : path);
        }
      }
    } catch (error) {
      notifyError(
        error?.data?.message || error?.message || "Invalid email or password"
      );
    } finally {
      setLoadingSub(false);
    }
  };

  // ReCAPTCHA handler
  const onChange = (value) => {
    setReCap(value);
  };

  return (
    <div className="login">
      <form onSubmit={getvalue}>
        <div className="box-login row">
          <div className="col-lg-12 inps">
            <div className="inp-icon">
              <MailOutlineIcon className="icon" />
              <input
                name="email"
                type="email"
                autoComplete="off"
                placeholder={t("Enter your Email")}
                required
                onChange={getdata}
              />
              <span>put your email please with @</span>
            </div>

            <div className="inp-icon">
              <LockIcon className="icon" />
              <input
                name="password"
                type="password"
                autoComplete="off"
                placeholder={t("Enter your password")}
                required
                onChange={getdata}
              />
            </div>

            <div className="text-center">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                security="6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe"
                onChange={onChange}
              />
            </div>

            {error && (
              <p style={{ color: "#970000", fontSize: "17px" }}>
                {error.data.message}
              </p>
            )}

            <button className="btn" disabled={!isValid || !recap || loadingSub}>
              {loadingSub ? `${t("loading")}...` : t("Login")}
            </button>

            <hr />
            <div className="policy-ask text-center">
              <p>
                By signing up, you agree to our Terms & Condition, Privacy
                Policy.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
