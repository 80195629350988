import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const appointmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all appointments
    getAppointments: builder.query({
      query: (arg) => ({
        url: "/cors/appointments",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.appointments.data;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      providesTags: ["Appointments"],
    }),

    // Get single appointment
    getAppointmentById: builder.query({
      query: (arg) => ({
        url: `/cors/appointments/${arg.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.item;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      providesTags: (result, error, arg) => [
        { type: "Appointment", id: arg.id },
      ],
    }),

    // Create appointment
    createAppointment: builder.mutation({
      query: (arg) => ({
        url: "/cors/appointments",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        notifysuccess(response.message);
        return response;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      invalidatesTags: ["Appointments"],
    }),

    // Update appointment
    updateAppointment: builder.mutation({
      query: (arg) => ({
        url: `/cors/appointments/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        notifysuccess(response.message);
        return response;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Appointment", id: arg.id },
        "Appointments",
      ],
    }),

    // Delete appointment
    deleteAppointment: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/cors/appointments/${id}`,
              method: "DELETE",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          const results = await Promise.all(deletePromises);
          console.log("Delete Appointment Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Appointments"],
    }),

    // Create transaction
    createTransaction: builder.mutation({
      query: (arg) => ({
        url: "/cors/appointments/add-transaction",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        notifysuccess(response.message);
        return response;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      invalidatesTags: (result) => [
        { type: "Appointment", id: result?.appointment_id },
        "Appointments",
      ],
    }),

    // Create payment
    createPayment: builder.mutation({
      query: (arg) => ({
        url: "/cors/appointments/add-payment",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        notifysuccess(response.message);
        return response;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      invalidatesTags: (result) => [
        { type: "Appointment", id: result?.appointment_id },
        "Appointments",
      ],
    }),

    // Create session
    createSession: builder.mutation({
      query: (arg) => ({
        url: "/cors/appointments/add-new-session",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        notifysuccess(response.message);
        return response;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
      invalidatesTags: (result) => [
        { type: "Appointment", id: result?.appointment_id },
        "Appointments",
      ],
    }),
  }),
});

export const {
  useGetAppointmentsQuery,
  useGetAppointmentByIdQuery,
  useCreateAppointmentMutation,
  useUpdateAppointmentMutation,
  useDeleteAppointmentMutation,
  useCreateTransactionMutation,
  useCreatePaymentMutation,
  useCreateSessionMutation,
} = appointmentsApi;
