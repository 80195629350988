import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import ActionIcons from "../../components/ActionIcons/ActionIcons";
import ModalComponent from "../../utils/ModalComponent";
import DeleteItem from "../../components/DeleteItem/DeleteItem";
import {
  useGetPatientsQuery,
  useDeletePatientMutation,
} from "../../services/patientsAPI";
import { notifyError, notifysuccess } from "../../Notification";
import { useNavigate } from "react-router-dom";
import PopUpUser from "./PopUpUser";

function MainUser() {
  const [t, i18n] = useTranslation();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [open, setOpen] = useState({ status: false, type: "", data: null });
  const navigate = useNavigate();

  // RTK Query hooks
  const {
    data: users,
    isLoading,
    error: errorAll,
  } = useGetPatientsQuery({
    lang: i18n.language,
  });

  const [deletePatient] = useDeletePatientMutation();

  // Handler functions
  const handleEdit = (params) => {
    navigate(`/users/edit/${params.row.id}`);
  };

  const handleView = (params) => {
    setOpen({ status: true, type: "view", data: params });
  };

  const handleDelete = (params) => {
    setOpen({ status: true, type: "delete", data: params });
  };

  const DeletePatient = async () => {
    try {
      let dataobj = new FormData();
      dataobj.append("_method", "DELETE");

      const ids = open.type === "all" ? selectedRowIds : [open.data.id];

      const result = await deletePatient({
        id: ids,
        data: dataobj,
      }).unwrap();

      if (result[0]?.data?.success) {
        notifysuccess(result[0]?.data?.message || t("Deleted successfully"));
        setOpen({ status: false, type: "", data: null });

        if (open.type === "all") {
          setSelectedRowIds([]);
        }
      } else {
        notifyError(
          result[0]?.data?.message || t("Some items failed to delete")
        );
      }
    } catch (error) {
      notifyError(error?.data?.message || t("Failed to delete"));
      setOpen({ status: false, type: "", data: null });
    }
  };

  const handleBulkDelete = () => {
    setOpen({ status: true, type: "all", data: null });
  };

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 60, sortable: "desc" },
    {
      field: "name",
      headerName: t("Patient Name"),
      width: 230,
      renderCell: (params) => (
        <div className="cellWithImg">
          {params.row.image ? (
            <img
              className="cellImg"
              src={`${"BASE_URL"}/storage/${params.row.image}`}
              alt="avatar"
            />
          ) : (
            <div className="boxImageChar">
              <span>{params?.row?.name?.slice(0, 1)}</span>
            </div>
          )}
          {params?.row?.name}
        </div>
      ),
    },
    {
      field: "gender",
      headerName: t("Gender"),
      sortable: false,
      width: 150,
    },
    {
      field: "phone_number",
      headerName: t("Phone Number"),
      sortable: false,
      width: 200,
    },
    {
      field: "address",
      headerName: t("Address"),
      sortable: false,
      width: 180,
    },
    {
      field: "birthday",
      headerName: t("Date Of Birth"),
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <div className="remaining">{params.row.date_of_birthday}</div>
      ),
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <ActionIcons
          onEdit={() => handleEdit(params)}
          onView={() => handleView(params)}
          onDelete={() => handleDelete(params)}
        />
      ),
    },
  ];

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const goToAddNew = () => {
    navigate("/users/add");
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={users}
          selectedRowIds={selectedRowIds}
          title={t("Patients")}
          filter="users"
          AddNewFn={goToAddNew}
          onDelete={handleBulkDelete}
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : isLoading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={users || []}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>

        <ModalComponent
          width={open.type === "delete" ? 450 : 800}
          open={open.status}
          onClose={() => setOpen({ status: false, type: "", data: null })}
        >
          {open.type === "view" && <PopUpUser id={open.data.id} />}
          {(open.type === "delete" || open.type === "all") && (
            <DeleteItem
              data={open.data}
              onClose={() => setOpen({ status: false, type: "", data: null })}
              title="Patient"
              deleteFn={DeletePatient}
              selectedRowIds={selectedRowIds}
              type={open.type}
            />
          )}
        </ModalComponent>
      </div>
    </div>
  );
}

export default MainUser;
