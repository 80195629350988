import React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCardIcon from "@mui/icons-material/AddCard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const ActionIcons = ({
  onEdit,
  onView,
  onDelete,
  onManage,
  onTransaction,
  handleFormComponet,
}) => {
  return (
    <Stack direction="row" spacing={-0.5}>
      {handleFormComponet && handleFormComponet()}
      {onView && (
        <IconButton
          aria-label="view"
          onClick={onView}
          sx={{ color: "#6a15de" }}
        >
          <VisibilityIcon />
        </IconButton>
      )}
      {onEdit && (
        <IconButton aria-label="edit" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      )}
      {onManage && (
        <IconButton
          aria-label="manage"
          onClick={onManage}
          sx={{ color: "#1976d2" }}
        >
          <AddCardIcon />
        </IconButton>
      )}
      {onTransaction && (
        <IconButton
          aria-label="transaction"
          onClick={onTransaction}
          sx={{ color: "#00a152" }}
        >
          <CurrencyExchangeIcon />
        </IconButton>
      )}
      {onDelete && (
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          sx={{ color: "crimson" }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default ActionIcons;
