import {
  CircularProgress,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

import { useDispatch } from "react-redux";
import { notifyError } from "../../../Notification";
import {
  createTransaction,
  fetchAppointments,
  updateAppointment,
} from "../../../store/appointmentsSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function ManageTransaction({ type, onClose, data }) {
  const [t, i18n] = useTranslation();
  console.log("datadata", data);
  let { loadingBTN } = useSelector((state) => state.persistData.appointments);

  let dispatch = useDispatch();

  const onsubmitfn = (e) => {
    e.preventDefault();

    let newdata = {
      ...inp,
      _method: "PUT",
    };
    dispatch(createTransaction({ data: inp, lang: i18n.language })).then(
      (response) => {
        if (response.payload.success) {
          dispatch(fetchAppointments({ lang: i18n.language }));
          onClose();
        } else {
          notifyError("Failed to update doctor. Please try again.");
        }
      }
    );
  };

  const [inp, setInp] = useState({
    appointment_payment_id: data.id,
  });

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  const handleChangeDate = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.format("YYYY-MM-DD") };
    });
  };

  return (
    <>
      <DialogTitle>
        <Typography
          variant="h6"
          gutterBottom
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {type === "edit"
            ? t("Edit transaction")
            : type === "view"
            ? t("View transaction")
            : t("Add transaction")}
        </Typography>
      </DialogTitle>
      <>
        <form class="row" onSubmit={(e) => onsubmitfn(e)}>
          <div className="col-lg-12">
            <div className="form-group ">
              <TextField
                id={"text"}
                type={"number"}
                label={t("Cost")}
                name={"cost"}
                required
                onChange={(e) => getValue(e)}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel id="Date">{t("Date")}:</InputLabel>
              <DatePicker
                onChange={(newValue) => {
                  handleChangeDate(newValue, "selected_datetime");
                }}
                defaultValue={null}
              />
            </LocalizationProvider>
          </div>
          <div className="col-lg-12">
            <div
              className="form-group "
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "10px",
                paddingLeft: "7px",
              }}
            >
              <p>
                {t("Status")}:<sup className="redstar">*</sup>
              </p>
              <Switch
                defaultChecked={true}
                onChange={(event) => handleSwitchChange(event, "active")}
              />
            </div>
          </div>

          <div className="btn-lest mt-3">
            {loadingBTN ? (
              <button type="text" disabled className="btn btn-primary-rgba ">
                <CheckCircleIcon color="#fff" /> {t("Loading")}...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary-rgba">
                <CheckCircleIcon color="#fff" />
                {type === "edit" ? t("Edit") : t("Create")}
              </button>
            )}

            <span onClick={onClose} className="btn btn-danger-rgba">
              <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
            </span>
          </div>
        </form>
      </>
    </>
  );
}

export default ManageTransaction;
