import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDoctors } from "../../../store/doctorsSlice";
import { getAllpatient } from "../../../store/patientslice";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderComponent from "../../../components/HeaderComponents/HeaderComponent";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { notifyError } from "../../../Notification";
import { createAppointment } from "../../../store/appointmentsSlice";
import {
  useCreateAppointmentMutation,
  useUpdateAppointmentMutation,
  useGetAppointmentByIdQuery,
} from "../../../services/appointmentsAPI";
import { useGetDoctorsQuery } from "../../../services/doctorAPI";
import { useGetPatientsQuery } from "../../../services/patientsAPI";

function EditCreateAppo() {
  const { name: type, id } = useParams();
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  let { data: dataAuth } = useSelector((state) => state.persistData.auth);

  // RTK Query hooks
  const [createAppointment, { isLoading: isCreating }] =
    useCreateAppointmentMutation();
  const [updateAppointment, { isLoading: isUpdating }] =
    useUpdateAppointmentMutation();

  const {
    data: singleData,
    isLoading: loadingSingle,
    error: errorSingle,
  } = useGetAppointmentByIdQuery(
    { id, lang: i18n.language },
    { skip: type === "add" }
  );

  const {
    data: doctors,
    isLoading: loadingDoctors,
    error: errorDoctors,
  } = useGetDoctorsQuery({ lang: i18n.language });

  const {
    data: patients,
    isLoading: loadingPatients,
    error: errorPatients,
  } = useGetPatientsQuery({ lang: i18n.language });

  const [inp, setInp] = useState({
    active: 1,
    status: "PENDING",
    company_id: dataAuth?.user?.company_id,
    appointment_details: [{ status: "PENDING" }],
    appointment_payments: [{ status: "PENDDING" }],
    "appointment_payments[0][currency_id]": 1,
  });

  // Load single appointment data
  useEffect(() => {
    if (type === "edit" && singleData) {
      setInp({
        ...singleData,
        status: singleData.status,
        doctor_id: singleData.doctor_id,
        patient_id: singleData.patient_id,
        appointment_details: [
          {
            ...singleData.appointment_details[0],
            status: singleData.appointment_details[0]?.status || "PENDING",
          },
        ],
        appointment_payments: [
          {
            ...singleData.appointment_payments[0],
            status: singleData.appointment_payments[0]?.status || "PENDDING",
          },
        ],
      });
    }
  }, [type, singleData]);

  // Form submission handler
  const sendData = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      Object.entries(inp).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === "object") {
              Object.entries(item).forEach(([subKey, subValue]) => {
                formData.append(`${key}[${index}][${subKey}]`, subValue);
              });
            } else {
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else if (value !== undefined && value !== null) {
          formData.append(key, value);
        }
      });

      if (type === "add") {
        const result = await createAppointment({
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result.success) {
          navigate("/appointments");
        }
      } else if (type === "edit") {
        formData.append("_method", "PUT");
        const result = await updateAppointment({
          id: singleData.id,
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result.success) {
          navigate("/appointments");
        }
      }
    } catch (error) {
      notifyError(error?.data?.message || "An error occurred");
    }
  };

  // Combined loading and error states
  const hasError = errorDoctors || errorPatients || errorSingle;
  const isLoading = loadingDoctors || loadingPatients || loadingSingle;

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <HeaderComponent
        title={type === "edit" ? t("Edit Appointment") : t("Add Appointment")}
      ></HeaderComponent>
      <div className="two-box">
        {hasError ? (
          <ErrorCompo />
        ) : isLoading ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="Details row" onSubmit={sendData}>
            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="Doctor">{t("Doctor")}</InputLabel>
                  <Select
                    labelId="Doctor"
                    name="doctor_id"
                    id="Doctor"
                    required
                    label={t("Doctor")}
                    onChange={(e) =>
                      setInp((prev) => ({ ...prev, doctor_id: e.target.value }))
                    }
                  >
                    {loadingDoctors ? (
                      // Display a loading indicator if data is still being fetched
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      // Map over the genders array to create MenuItems for each gender option
                      doctors &&
                      doctors?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="Doctor">{t("Patient")}</InputLabel>
                  <Select
                    labelId="Patient"
                    name="patient_id"
                    id="Patient"
                    required
                    label={t("Patient")}
                    onChange={(e) =>
                      setInp((prev) => ({
                        ...prev,
                        patient_id: e.target.value,
                      }))
                    }
                  >
                    {loadingPatients ? (
                      // Display a loading indicator if data is still being fetched
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      // Map over the genders array to create MenuItems for each gender option
                      patients &&
                      patients?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="status">{t("Appointment Status")}</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    id="status"
                    required
                    label="status"
                    value={inp.status}
                    onChange={(e) =>
                      setInp((prev) => ({ ...prev, status: e.target.value }))
                    }
                  >
                    <MenuItem value={"PENDING"}>{t("PENDING")}</MenuItem>
                    <MenuItem value={"DONE"}>{t("DONE")}</MenuItem>
                    <MenuItem value={"CANCELED"}>{t("CANCELED")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div
                className="form-group "
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "20px",
                  paddingLeft: "7px",
                }}
              >
                <p>
                  {t("Status")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  defaultChecked={true}
                  onChange={(event) =>
                    setInp((prev) => ({
                      ...prev,
                      active: event.target.checked ? 1 : 0,
                    }))
                  }
                />
              </div>
            </div>

            <p style={{ margin: "0px" }}>{t("Appointment Details")}:</p>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Extra information")}
                  name={"location"}
                  required
                  onChange={(e) =>
                    setInp((prev) => {
                      const extra_information = e.target.value;
                      return {
                        ...prev,
                        appointment_details:
                          prev?.appointment_details?.length > 0
                            ? [
                                {
                                  ...prev.appointment_details[0],
                                  extra_information: extra_information,
                                },
                              ]
                            : [{ extra_information: extra_information }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <InputLabel id="Date">{t("Date")}:</InputLabel>
                <DatePicker
                  onChange={(newValue) => {
                    setInp((prev) => {
                      return {
                        ...prev,
                        appointment_details:
                          prev?.appointment_details?.length > 0
                            ? [
                                {
                                  ...prev.appointment_details[0],
                                  selected_datetime:
                                    newValue.format("YYYY-MM-DD"),
                                },
                              ]
                            : [
                                {
                                  selected_datetime:
                                    newValue.format("YYYY-MM-DD"),
                                },
                              ],
                      };
                    });
                  }}
                  defaultValue={null}
                />
              </LocalizationProvider>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Note")}
                  name={"location"}
                  //   required
                  onChange={(e) =>
                    setInp((prev) => {
                      const note = e.target.value;
                      return {
                        ...prev,
                        appointment_details:
                          prev?.appointment_details?.length > 0
                            ? [
                                {
                                  ...prev.appointment_details[0],
                                  note: note,
                                },
                              ]
                            : [{ note: note }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="status">{t("Details Status")}</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    id="status"
                    required
                    label="status"
                    value={inp?.appointment_details[0]?.status}
                    onChange={(e) =>
                      setInp((prev) => {
                        const status = e.target.value;
                        return {
                          ...prev,
                          appointment_details:
                            prev?.appointment_details?.length > 0
                              ? [
                                  {
                                    ...prev.appointment_details[0],
                                    status: status,
                                  },
                                ]
                              : [{ status: status }],
                        };
                      })
                    }
                  >
                    <MenuItem value={"PENDING"}>{t("PENDING")}</MenuItem>
                    <MenuItem value={"DONE"}>{t("DONE")}</MenuItem>
                    <MenuItem value={"CANCELED"}>{t("CANCELED")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <p style={{ margin: "0px" }}>{t("Appointment Payment")}:</p>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="payment_method">
                    {t("Payment method")}
                  </InputLabel>
                  <Select
                    labelId="Payment method"
                    name="payment_method"
                    id="payment_method"
                    required
                    label="payment_method"
                    onChange={(e) =>
                      setInp((prev) => {
                        const payment_method = e.target.value;
                        return {
                          ...prev,
                          appointment_payments:
                            prev?.appointment_payments?.length > 0
                              ? [
                                  {
                                    ...prev.appointment_payments[0],
                                    payment_method: payment_method,
                                  },
                                ]
                              : [{ payment_method: payment_method }],
                        };
                      })
                    }
                  >
                    <MenuItem value={"CASH"}>{t("CASH")}</MenuItem>
                    <MenuItem value={"CARD"}>{t("CARD")}</MenuItem>
                    <MenuItem value={"TRANSFER"}>{t("TRANSFER")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="status">{t("Payment Status")}</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    id="status"
                    required
                    label="status"
                    value={inp?.appointment_payments[0]?.status}
                    onChange={(e) =>
                      setInp((prev) => {
                        const status = e.target.value;
                        return {
                          ...prev,
                          appointment_payments:
                            prev?.appointment_payments?.length > 0
                              ? [
                                  {
                                    ...prev.appointment_payments[0],
                                    status: status,
                                  },
                                ]
                              : [{ status: status }],
                        };
                      })
                    }
                  >
                    <MenuItem value={"PENDDING"}>{t("PENDING")}</MenuItem>
                    <MenuItem value={"DONE"}>{t("DONE")}</MenuItem>
                    <MenuItem value={"CANCELED"}>{t("CANCELED")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"number"}
                  label={t("Total Amount")}
                  name={"total"}
                  required
                  onChange={(e) =>
                    setInp((prev) => {
                      const total = e.target.value;
                      return {
                        ...prev,
                        appointment_payments:
                          prev?.appointment_payments?.length > 0
                            ? [
                                {
                                  ...prev.appointment_payments[0],
                                  total: total,
                                },
                              ]
                            : [{ total: total }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"number"}
                  label={t("Cost")}
                  name={"cost"}
                  required
                  onChange={(e) =>
                    setInp((prev) => {
                      const cost = e.target.value;
                      return {
                        ...prev,
                        appointment_payments:
                          prev?.appointment_payments?.length > 0
                            ? [
                                {
                                  ...prev.appointment_payments[0],
                                  cost: cost,
                                },
                              ]
                            : [{ cost: cost }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div> */}

            <div
              className="btn-lest mt-5"
              style={{ margin: "40px !important", justifyContent: "center" }}
            >
              {isCreating || isUpdating ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span
                onClick={() => navigate(-1)}
                className="btn btn-danger-rgba"
              >
                <DoNotDisturbAltIcon color="#fff" /> {t("Back")}
              </span>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditCreateAppo;
