import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError } from "../Notification";

const token = Cookies.get("token");

export const commonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all genders
    getGenderList: builder.query({
      query: (arg) => ({
        url: "/core/common/list/genders",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.list;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
    }),

    // Get all chronic diseases
    getChronicDiseases: builder.query({
      query: (arg) => ({
        url: "/core/common/list/chronic-diseases",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.list;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
    }),

    // Get all weekdays
    getWeekdays: builder.query({
      query: (arg) => ({
        url: "/core/common/list/weekdays",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Weekdays Response:", response);
        return response.result.list;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
    }),

    // Get all categories
    getCategories: builder.query({
      query: (arg) => ({
        url: "/core/common/list/categories",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Categories Response:", response);
        return response.result.list;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
    }),

    // Get all access methods
    getAccessMethods: builder.query({
      query: (arg) => ({
        url: "/core/common/list/access-methods",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.list;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
    }),

    // Get all language speaks
    getLanguageSpeaks: builder.query({
      query: (arg) => ({
        url: "/core/common/list/language-speaks",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        return response.result.list;
      },
      transformErrorResponse: (error) => {
        notifyError(error.data?.message || "An error occurred");
        return error;
      },
    }),
  }),
});

export const {
  useGetGenderListQuery,
  useGetChronicDiseasesQuery,
  useGetWeekdaysQuery,
  useGetCategoriesQuery,
  useGetAccessMethodsQuery,
  useGetLanguageSpeaksQuery,
} = commonApi;
