import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import {
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { notifyError } from "../../../Notification";
import {
  useCreatePaymentMutation,
  useGetAppointmentByIdQuery,
} from "../../../services/appointmentsAPI";

function AddPayment({ type, onClose, data }) {
  const [t, i18n] = useTranslation();

  // RTK Query hooks
  const [createPayment, { isLoading: loadingBTN }] = useCreatePaymentMutation();
  const { refetch: refetchAppointment } = useGetAppointmentByIdQuery(
    { id: data, lang: i18n.language },
    { skip: !data }
  );

  const [inp, setInp] = useState({
    active: 1,
    status: "PENDDING",
    appointment_detail_id: data,
    cuurency_id: "1",
  });

  const handleChangeDate = (e) => {
    setInp((prev) => ({
      ...prev,
      selected_datetime: e.format("YYYY-MM-DD"),
    }));
  };

  const getValue = (e) => {
    setInp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onsubmitfn = async (e) => {
    e.preventDefault();

    try {
      const result = await createPayment({
        data: inp,
        lang: i18n.language,
      }).unwrap();

      if (result.success) {
        await refetchAppointment();
        onClose();
      }
    } catch (error) {
      notifyError(
        error?.data?.message || "Failed to add Payment. Please try again."
      );
    }
  };

  return (
    <>
      <DialogTitle>
        <Typography
          variant="h6"
          gutterBottom
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {type === "edit"
            ? t("Edit Payment")
            : type === "view"
            ? t("View Payment")
            : t("Add Payment")}
        </Typography>
      </DialogTitle>
      <form className="row" onSubmit={onsubmitfn}>
        <div className="col-lg-12">
          <div className="form-group mb-4 mt-4">
            <TextField
              id={"text"}
              type={"number"}
              label={t("Total")}
              name={"total"}
              required
              onChange={getValue}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <InputLabel id="Date">{t("Date")}:</InputLabel>
            <DatePicker
              onChange={(newValue) => handleChangeDate(newValue)}
              defaultValue={null}
            />
          </LocalizationProvider>
        </div>

        <div className="col-lg-12">
          <div className="form-group mb-4 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="status">{t("Payment method")}</InputLabel>
              <Select
                labelId="payment method"
                name="payment_method"
                id="payment_method"
                required
                label="Payment method"
                value={inp.payment_method}
                onChange={getValue}
              >
                <MenuItem value={"CASH"}>CASH</MenuItem>
                <MenuItem value={"CARD"}>CARD</MenuItem>
                <MenuItem value={"TRANSFER"}>TRANSFER</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="btn-lest mt-3">
          {loadingBTN ? (
            <button type="text" disabled className="btn btn-primary-rgba">
              <CheckCircleIcon color="#fff" /> {t("Loading")}...
            </button>
          ) : (
            <button type="submit" className="btn btn-primary-rgba">
              <CheckCircleIcon color="#fff" />
              {type === "edit" ? t("Edit") : t("Create")}
            </button>
          )}

          <span onClick={onClose} className="btn btn-danger-rgba">
            <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
          </span>
        </div>
      </form>
    </>
  );
}

export default AddPayment;
