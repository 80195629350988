import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderComponent from "../../../components/HeaderComponents/HeaderComponent";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Person2Icon from "@mui/icons-material/Person2";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./HandleAppointMentstyle.scss";
import Datatable from "../../../components/datatable/Datatable";
import CollapseTable from "../../../components/CollapseTable/CollapseTable";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import AddDetails from "./AddDetails";
import AddPayment from "./AddPayment";
import AddTransaction from "./AddTransaction";
import { useGetAppointmentByIdQuery } from "../../../services/appointmentsAPI";
import ActionIcons from "../../../components/ActionIcons/ActionIcons";

function ManageAppointment() {
  const { id } = useParams();
  const [t, i18n] = useTranslation();
  const { data: dataAuth } = useSelector((state) => state.persistData.auth);

  // Remove polling and just use the basic query with refetch capability
  const {
    data: singleData,
    isLoading: loadingSingle,
    error: errorSingle,
    refetch,
  } = useGetAppointmentByIdQuery({ id, lang: i18n.language });

  const [open, setOpen] = useState({
    status: false,
    type: "",
    data: "",
    name: "",
  });

  const handleEdit = (params) => {
    setOpen({
      status: true,
      type: "edit",
      data: params.row,
      name: "Deatils",
    });
  };

  const handleDelete = (params) => {
    setOpen({
      status: true,
      type: "delete",
      data: params.row,
      name: "Deatils",
    });
  };

  const DetailsColumn = [
    { field: "id", headerName: t("ID"), width: 80, sortable: "desc" },
    {
      field: "selected_datetime",
      headerName: t("Date"),
      width: 200,
      renderCell: (params) => (
        <div className="remaining">
          {params.row.selected_datetime.split(" ")[0]}
        </div>
      ),
    },
    {
      field: "extra_information",
      headerName: t("Extra Information"),
      width: 300,
    },
    { field: "note", headerName: t("Note"), width: 300 },
    {
      field: "status",
      headerName: t("Status"),
      width: 250,
      renderCell: (params) => (
        <div className={`status ${params.row.status}`}>{params.row.status}</div>
      ),
    },
    // {
    //   field: "Action",
    //   headerName: t("Action"),
    //   sortable: false,
    //   width: 120,
    //   renderCell: (params) => (
    //     <ActionIcons
    //       onEdit={""}
    //       onDelete={""}
    //       // onEdit={() => handleEdit(params)}
    //       // onDelete={() => handleDelete(params)}
    //     />
    //   ),
    // },
  ];

  const handleClickToAddTransaction = () => {
    setOpen({
      status: true,
      type: "add",
      data: {
        paymentID: singleData?.appointment_payments[0]?.id,
        IdAppo: singleData?.id,
      },
      name: "Transaction",
    });
  };

  // Handle modal close and data refresh
  const handleModalClose = async () => {
    setOpen({ status: false, type: "", data: null, name: null });
    // Only refetch when a modal closes after an action
    // await refetch();
  };

  const getTranslation = (translations, currentLanguage) => {
    if (!translations || !Array.isArray(translations)) return null;
    const currentLangTranslation = translations.find(
      (translation) => translation.locale === currentLanguage
    );
    const fallbackTranslation = translations.find(
      (translation) => translation.locale !== currentLanguage
    );
    return currentLangTranslation?.name || fallbackTranslation?.name || null;
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1 single-appo">
      <Navbar />
      <HeaderComponent title={t("Manage Appointment")} />
      <div className="two-box">
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <>
            <div className="top-detaisl row">
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <Person2Icon />
                  </div>
                  <div className="dit">
                    <span>{t("Doctor Name")}:</span>
                    <p>
                      {getTranslation(
                        singleData?.doctor?.translations,
                        i18n.language
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <Person2Icon />
                  </div>
                  <div className="dit">
                    <span>{t("Patient Name")}:</span>
                    <p>
                      {getTranslation(
                        singleData?.patient?.translations,
                        i18n.language
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <AttachMoneyIcon />
                  </div>
                  <div className="dit">
                    <span>{t("Total Amount")}:</span>
                    <p>250</p>
                  </div>
                </div>
              </div>
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <AttachMoneyIcon />
                  </div>
                  <div className="dit">
                    <span>{t("Total Paid")}:</span>
                    <p>100</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row box-big-details">
              <div className="wraper-title mt-5">
                <h5 className="">{t("Appointment Details")}</h5>
                <div className="btn-list">
                  <button
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen({
                        status: true,
                        type: "add",
                        data: singleData?.id,
                        name: "Deatils",
                      });
                    }}
                  >
                    <AddIcon /> {t("Add new appointment details")}
                  </button>
                </div>
              </div>
              <Datatable
                height={"auto"}
                userColumns={DetailsColumn}
                userRows={singleData?.appointment_details || []}
              />
            </div>

            <div className="row box-big-details">
              <div className="wraper-title mt-5">
                <h5 className="mt-5">{t("Appointment Payments")}</h5>
                <div className="btn-list">
                  <button
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen({
                        status: true,
                        type: "add",
                        data: singleData?.appointment_details[0]?.id,
                        name: "payment",
                      });
                    }}
                  >
                    <AddIcon /> {t("Add a new payment")}
                  </button>
                </div>
              </div>
              <CollapseTable
                CLickAdd={handleClickToAddTransaction}
                t={t}
                appointmentPayments={singleData?.appointment_payments || []}
              />
            </div>
          </>
        )}
      </div>

      <ModalComponent
        width={open.type === "delete" ? 500 : 400}
        open={open.status}
        onClose={handleModalClose}
      >
        {open.name === "Deatils" && (
          <AddDetails
            type={open.type}
            onClose={handleModalClose}
            data={open.data}
          />
        )}
        {open.name === "payment" && (
          <AddPayment
            type={open.type}
            onClose={handleModalClose}
            data={open.data}
          />
        )}
        {open.name === "Transaction" && (
          <AddTransaction
            type={open.type}
            onClose={handleModalClose}
            data={open.data}
          />
        )}
      </ModalComponent>
    </div>
  );
}

export default ManageAppointment;
