import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import ActionIcons from "../../components/ActionIcons/ActionIcons";
import ModalComponent from "../../utils/ModalComponent";
import DeleteItem from "../../components/DeleteItem/DeleteItem";
import {
  useGetDoctorsQuery,
  useDeleteDoctorMutation,
} from "../../services/doctorAPI";
import { notifyError, notifysuccess } from "../../Notification";
import { useNavigate } from "react-router-dom";

function MainDoctor() {
  const [t, i18n] = useTranslation();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [open, setOpen] = useState({ status: false, type: "", data: null });
  const navigate = useNavigate();

  // RTK Query hooks
  const {
    data: doctors,
    isLoading,
    error: errorAll,
  } = useGetDoctorsQuery({
    lang: i18n.language,
  });

  const [deleteDoctor] = useDeleteDoctorMutation();

  // Handler functions
  const handleEdit = (params) => {
    navigate(`/doctor/edit/${params.row.id}`);
  };

  const handleView = (params) => {
    navigate(`/doctor/${params.row.id}`);
  };

  const handleDelete = (params) => {
    setOpen({ status: true, type: "delete", data: params });
  };

  const DeleteDoctor = async () => {
    try {
      let dataobj = new FormData();
      dataobj.append("_method", "DELETE");

      const ids = open.type === "all" ? selectedRowIds : [open.data.id];

      const result = await deleteDoctor({
        id: ids,
        data: dataobj,
      }).unwrap();

      if (result[0]?.data?.success) {
        notifysuccess(result[0]?.data?.message || t("Deleted successfully"));
        setOpen({ status: false, type: "", data: null });

        if (open.type === "all") {
          setSelectedRowIds([]);
        }
      } else {
        notifyError(
          result[0]?.data?.message || t("Some items failed to delete")
        );
      }
    } catch (error) {
      notifyError(error?.data?.message || t("Failed to delete"));
      setOpen({ status: false, type: "", data: null });
    }
  };

  const handleBulkDelete = () => {
    setOpen({ status: true, type: "all", data: null });
  };

  const userColumns = [
    { field: "uuid", headerName: t("ID"), width: 80 },
    {
      field: "name",
      headerName: t("Doctor"),
      width: 180,
      renderCell: (params) => (
        <div className="cellWithImg">
          {params.row.user_picture ? (
            <img
              className="cellImg"
              src={`${"BASE_URL"}/storage/${params.row.user_picture}`}
              alt="avatar"
            />
          ) : (
            <div className="boxImageChar">
              <span>{params?.row?.name?.slice(0, 1)}</span>
            </div>
          )}
          {params?.row?.name}
        </div>
      ),
    },
    { field: "email", headerName: t("Email"), sortable: false, width: 180 },
    { field: "gender", headerName: t("Gender"), sortable: false, width: 100 },
    {
      field: "phone_number",
      headerName: t("Phone Number"),
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div className="remaining">
          {params.row?.doctor_contacts[0]?.phone_number}
        </div>
      ),
    },
    {
      field: "location",
      headerName: t("Address"),
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div className="remaining">
          {params.row?.doctor_contacts[0]?.address}
        </div>
      ),
    },
    {
      field: "date_of_birthday",
      headerName: t("Date Of Birth"),
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div className="remaining">
          {params.row.date_of_birthday.split(" ")[0]}
        </div>
      ),
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <ActionIcons
          onEdit={() => handleEdit(params)}
          onView={""}
          onDelete={() => handleDelete(params)}
        />
      ),
    },
  ];

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const goToAddNew = () => {
    navigate("/doctor/add");
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={doctors}
          selectedRowIds={selectedRowIds}
          title={t("Doctors")}
          filter="doctors"
          AddNewFn={goToAddNew}
          onDelete={handleBulkDelete}
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : isLoading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={doctors || []}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>

        <ModalComponent
          width={open.type === "delete" ? 450 : 600}
          open={open.status}
          onClose={() => setOpen({ status: false, type: "", data: null })}
        >
          {(open.type === "delete" || open.type === "all") && (
            <DeleteItem
              data={open.data}
              onClose={() => setOpen({ status: false, type: "", data: null })}
              title="Doctor"
              deleteFn={DeleteDoctor}
              selectedRowIds={selectedRowIds}
              type={open.type}
            />
          )}
        </ModalComponent>
      </div>
    </div>
  );
}

export default MainDoctor;
