import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AddCardIcon from "@mui/icons-material/AddCard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Navbar from "../../../components/Navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import ModalComponent from "../../../utils/ModalComponent";
import DeleteItem from "../../../components/DeleteItem/DeleteItem";
import {
  useGetAppointmentsQuery,
  useDeleteAppointmentMutation,
} from "../../../services/appointmentsAPI";
import { notifyError, notifysuccess } from "../../../Notification";
import UpdateAppointment from "./UpdateAppointment";
import ManageTransaction from "./ManageTransaction";
import ActionIcons from "../../../components/ActionIcons/ActionIcons";

function MainAppointment() {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [open, setOpen] = useState({ status: false, type: "", data: null });

  // RTK Query hooks
  const {
    data: appointments,
    isLoading,
    error: errorAll,
  } = useGetAppointmentsQuery({
    lang: i18n.language,
  });

  const [deleteAppointment] = useDeleteAppointmentMutation();

  // Handler functions
  const handleEdit = (params) => {
    setOpen({ status: true, type: "edit", data: params.row });
  };

  const handleManage = (id) => {
    navigate(`/manageAppointment/${id}`);
  };

  const handleTransaction = (params) => {
    setOpen({ status: true, type: "Transaction", data: params.row });
  };

  const handleDelete = (params) => {
    setOpen({ status: true, type: "delete", data: params });
  };

  const DeleteAppointment = async () => {
    try {
      let dataobj = new FormData();
      dataobj.append("_method", "DELETE");

      const ids = open.type === "all" ? selectedRowIds : [open.data.id];

      const result = await deleteAppointment({
        id: ids,
        data: dataobj,
      }).unwrap();

      if (result[0]?.data?.success) {
        notifysuccess(result[0]?.data?.message || t("Deleted successfully"));
        setOpen({ status: false, type: "", data: null });

        if (open.type === "all") {
          setSelectedRowIds([]);
        }
      } else {
        notifyError(
          result[0]?.data?.message || t("Some items failed to delete")
        );
      }
    } catch (error) {
      notifyError(error?.data?.message || t("Failed to delete"));
      setOpen({ status: false, type: "", data: null });
    }
  };

  const handleBulkDelete = () => {
    setOpen({ status: true, type: "all", data: null });
  };

  const userColumns = [
    { field: "uuid", headerName: t("ID"), width: 80, sortable: "desc" },
    {
      field: "patient_name",
      headerName: t("Patient Name"),
      width: 220,
      renderCell: (params) => <div>{params?.row?.patient?.name}</div>,
    },
    {
      field: "doctor_name",
      headerName: t("Doctor Name"),
      width: 220,
      renderCell: (params) => <div>{params?.row?.doctor?.name}</div>,
    },
    { field: "Total_Amount", headerName: t("Total Amount"), width: 180 },
    { field: "Amount_Paid", headerName: t("Total Paid"), width: 180 },
    {
      field: "status",
      headerName: t("Status"),
      width: 150,
      renderCell: (params) => (
        <div className={`status ${params?.row?.status}`}>
          {params?.row?.status}
        </div>
      ),
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <ActionIcons
          onEdit={() => handleEdit(params)}
          onManage={() => handleManage(params.id)}
          // onTransaction={() => handleTransaction(params)}
          onDelete={() => handleDelete(params)}
        />
      ),
    },
  ];

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const goToAddNew = () => {
    navigate("/appointment/add");
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={appointments}
          selectedRowIds={selectedRowIds}
          title={t("Appointments")}
          filter="appointments"
          AddNewFn={goToAddNew}
          onDelete={handleBulkDelete}
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : isLoading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={appointments || []}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>

        <ModalComponent
          width={open.type === "delete" ? 450 : 600}
          open={open.status}
          onClose={() => setOpen({ status: false, type: "", data: null })}
        >
          {open.type === "edit" && (
            <UpdateAppointment
              data={open.data}
              type={open.type}
              onClose={() => setOpen({ status: false, type: "", data: null })}
            />
          )}
          {open.type === "Transaction" && (
            <ManageTransaction
              data={open.data}
              onClose={() => setOpen({ status: false, type: "", data: null })}
            />
          )}
          {(open.type === "delete" || open.type === "all") && (
            <DeleteItem
              data={open.data}
              onClose={() => setOpen({ status: false, type: "", data: null })}
              title="Appointment"
              deleteFn={DeleteAppointment}
              selectedRowIds={selectedRowIds}
              type={open.type}
            />
          )}
        </ModalComponent>
      </div>
    </div>
  );
}

export default MainAppointment;
