import "./Navbar.scss";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SegmentIcon from "@mui/icons-material/Segment";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { Logout, islogged, showsliderfn } from "../../store/authslice.js";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLocalStorage from "use-local-storage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEffect } from "react";
import { toggle } from "../../store/theme";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assits/logo_app.svg";
import useClickOutside from "../../utils/useClickOutside.js";
export function FadeMenu({ lang, profile }) {
  const [t, i18n] = useTranslation();
  const [lan, setLang] = useLocalStorage("lang", i18n.language);
  let dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    i18n.changeLanguage(lan);
  }, [lan]);

  let navigate = useNavigate();
  return (
    <div className="dropdown12">
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {lang && <LanguageIcon />}
        {profile && <KeyboardArrowDownIcon />}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {profile && (
          <div>
            <MenuItem onClick={handleClose}>
              <div className="text-center name-profile">Haron</div>
            </MenuItem>
            <MenuItem onClick={handleClose}>{t("My account")}</MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                dispatch(Logout());
                navigate("/login");
                // window.location.reload();
              }}
            >
              {t("Logout")}
            </MenuItem>
          </div>
        )}

        {lang && (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                i18n.changeLanguage("en");
                setLang(i18n.language);
              }}
            >
              {t("english")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                i18n.changeLanguage("ar");
                setLang(i18n.language);
              }}
            >
              {t("arabic")}
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
}

function Navbar() {
  const [t, i18n] = useTranslation();
  let [notiIn, setNotiIn] = useState(false);
  let dispatch = useDispatch();
  let { dark } = useSelector((state) => state.persistData.themeslice);
  let navigate = useNavigate();
  let gotoweb = () => {
    navigate("/");
  };

  let domNode = useClickOutside(() => {
    setNotiIn(false);
  });

  return (
    <div className="topbar">
      <div className="topbar_wrapper">
        <p onClick={() => gotoweb()}>
          {t("Visit Site")} <OpenInNewIcon className="icon" />
        </p>
        <div className="big-icons-nav" ref={domNode}>
          {/*
         <div className="icon-topbar" onClick={() => setNotiIn(!notiIn)}>
         <span className="sp">3</span>
         <NotificationsNoneIcon />

         {notiIn && (
           <div className="box-noti-pop">
             <Link to="/notification" className="box-noti">
               <div className="header-noti">
                 <h6>New Order</h6>
                 <div className="icons">
                   <MoreHorizIcon />
                 </div>
               </div>
               <p>
                 Bla Bla Bdkasdkj la Bla Bla Bla Bdkasdkj la Bla Bla Bla
                 Bdkasdkj la Bla
               </p>
             </Link>

             <Link to="/notification" className="box-noti">
               <div className="header-noti">
                 <h6>New Order</h6>
                 <div className="icons">
                   <MoreHorizIcon />
                 </div>
               </div>
               <p>Bla Bla Bdkasdkj la Bla</p>
             </Link>

             <Link to="/notification" className="box-noti">
               <div className="header-noti">
                 <h6>New Order</h6>
                 <div className="icons">
                   <MoreHorizIcon />
                 </div>
               </div>
               <p>Bla Bla Bdkasdkj la Bla</p>
             </Link>
           </div>
         )}
       </div>
        */}
          <div className="icon-topbar">
            <FadeMenu lang="lang" />
          </div>
          {/*{!dark ? (
            <div className="icon-topbar">
              <WbSunnyIcon onClick={() => dispatch(toggle())} />
            </div>
          ) : (
            <div className="icon-topbar">
              <DarkModeIcon onClick={() => dispatch(toggle())} />
            </div>
          )}*/}

          <div className="icon-topbar">
            {/**<img src={imgprof} alt="..." />* */}
            <span className="image">H</span>
            <FadeMenu profile="profile" />
          </div>

          <div className="icon-topbar list">
            <SegmentIcon onClick={() => dispatch(showsliderfn())} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
