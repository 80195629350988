import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Navbar from "../../components/Navbar/Navbar";
import HeaderComponent from "../../components/HeaderComponents/HeaderComponent";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import dayjs from "dayjs";
import CheckMultiLangu from "../../components/CheckMultiLangu/CheckMultiLangu";
import {
  useCreatePatientMutation,
  useUpdatePatientMutation,
  useGetPatientByIdQuery,
} from "../../services/patientsAPI";
import {
  useGetGenderListQuery,
  useGetChronicDiseasesQuery,
  useGetAccessMethodsQuery,
  useGetLanguageSpeaksQuery,
} from "../../services/commonAPI";

function EditCreateUser() {
  const { name: type, id } = useParams();
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  let { data: dataAuth } = useSelector((state) => state.persistData.auth);

  // RTK Query hooks for common data
  const {
    data: genders,
    isLoading: loadingGen,
    error: errorGen,
  } = useGetGenderListQuery({ lang: i18n.language });

  const {
    data: chronicDiseases,
    isLoading: loadingChronic,
    error: errorChronic,
  } = useGetChronicDiseasesQuery({ lang: i18n.language });

  const {
    data: accessMethods,
    isLoading: loadingAccess,
    error: errorAccess,
  } = useGetAccessMethodsQuery({ lang: i18n.language });

  const {
    data: languageSpeaks,
    isLoading: loadingLanguage,
    error: errorLanguage,
  } = useGetLanguageSpeaksQuery({ lang: i18n.language });

  // RTK Query hooks for patient operations
  const [createPatient, { isLoading: isCreating }] = useCreatePatientMutation();
  const [updatePatient, { isLoading: isUpdating }] = useUpdatePatientMutation();

  const {
    data: singleData,
    isLoading: loadingSingle,
    error: errorSingle,
  } = useGetPatientByIdQuery(
    { id, lang: i18n.language },
    { skip: type === "add" }
  );

  // State management
  const [selectedLanguages, setSelectedLanguages] = useState(["en"]);
  const [inp, setInp] = useState({
    company_id: dataAuth?.user?.company_id,
    patients_language_speaks: [],
  });

  // Handlers
  const getValue = (e) => {
    if (e.target.name === "patients_language_speaks") {
      setInp((prev) => ({
        ...prev,
        patients_language_speaks: [e.target.value],
      }));
    } else {
      setInp((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSwitchChange = (e, name) => {
    setInp((prev) => ({
      ...prev,
      [name]: e.target.checked ? 1 : 0,
    }));
  };

  const handleChangeDate = (e) => {
    setInp((prev) => ({
      ...prev,
      date_of_birthday: e.format("YYYY-MM-DD"),
    }));
  };

  const toggleLanguage = (lang) => {
    setSelectedLanguages((prev) =>
      prev.includes(lang) ? prev.filter((l) => l !== lang) : [...prev, lang]
    );
  };

  // Load single patient data
  useEffect(() => {
    if (type === "edit" && singleData) {
      const translations = singleData.translations || [];
      const defaultValues = translations.reduce((acc, translation) => {
        acc[`translations[${translation.locale}][name]`] = translation.name;
        return acc;
      }, {});

      const allLocales = translations.map((translation) => translation.locale);
      setSelectedLanguages([...new Set([...selectedLanguages, ...allLocales])]);

      setInp({
        ...defaultValues,
        status: singleData.status,
        sensitive: singleData.sensitive,
        sensitive_note: singleData.sensitive_note,
        email: singleData.email,
        phone_number: singleData.phone_number,
        address: singleData.address,
        gender: singleData.gender,
        chronic_disease_id: singleData.chronic_disease_id,
        access_method_id: singleData.access_method_id,
        patients_language_speaks: [singleData?.patients_language_speaks[0]?.id],
        date_of_birthday: singleData.date_of_birthday,
      });
    }
  }, [type, singleData]);

  // Form submission
  const sendData = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      Object.entries(inp).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === "object") {
              Object.entries(item).forEach(([subKey, subValue]) => {
                formData.append(`${key}[${index}][${subKey}]`, subValue);
              });
            } else {
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else if (value !== undefined && value !== null) {
          formData.append(key, value);
        }
      });

      if (type === "add") {
        const result = await createPatient({
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result.success) {
          navigate("/users");
        }
      } else if (type === "edit") {
        formData.append("_method", "PUT");
        const result = await updatePatient({
          id: singleData.id,
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result.success) {
          navigate("/users");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Combined loading and error states
  const hasError =
    errorGen || errorChronic || errorAccess || errorLanguage || errorSingle;
  const isLoading =
    loadingGen ||
    loadingChronic ||
    loadingAccess ||
    loadingLanguage ||
    loadingSingle;

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <HeaderComponent
        title={type === "edit" ? t("Edit Patient") : t("Add Patient")}
      >
        <CheckMultiLangu
          toggleLanguage={toggleLanguage}
          selectedLanguages={selectedLanguages}
        />
      </HeaderComponent>
      <div className="two-box">
        {hasError ? (
          <ErrorCompo />
        ) : isLoading ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="Details row" onSubmit={sendData}>
            <div className="col-lg-3 col-sm-6 row">
              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div className="det-inp">
                  <label htmlFor="file">
                    <img
                      className="b6e5"
                      src={
                        "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                      }
                      alt="..."
                    />
                    <CloudUploadIcon className="icon ms-2" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    name="up"
                    placeholder="name"
                    style={{ display: "none" }}
                  />
                </div>

                <div className="form-group d-flex gap-2 justify-content-center align-items-center w-100 me-5">
                  <p>
                    {t("Status")}:<sup className="redstar">*</sup>
                  </p>
                  <Switch
                    defaultChecked={
                      type === "edit" && singleData && singleData.status == 1
                        ? true
                        : false
                    }
                    onChange={(event) => handleSwitchChange(event, "status")}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-sm-6 row">
              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"email"}
                    type={"email"}
                    label={t("Email")}
                    name={"email"}
                    required
                    defaultValue={
                      type === "edit" ? singleData && singleData.email : ""
                    }
                    onChange={(e) => getValue(e)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              {selectedLanguages.map(
                (lang) =>
                  selectedLanguages.includes(lang) && (
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group mb-4 mt-4">
                        <TextField
                          id={"text"}
                          type={"text"}
                          label={t("Name") + ` (${lang})`}
                          name={`translations[${lang}][name]`}
                          required
                          value={inp[`translations[${lang}][name]`] || ""}
                          onChange={(e) => getValue(e)}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  )
              )}

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="Gender">{t("Gender")}</InputLabel>
                    <Select
                      labelId="Gender"
                      name="gender"
                      id="Role"
                      defaultValue={
                        type === "edit" && singleData ? singleData.gender : ""
                      }
                      required
                      label="Role"
                      onChange={(e) => getValue(e)}
                    >
                      {loadingGen ? (
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        genders &&
                        genders?.map((gender) => (
                          <MenuItem key={gender.id} value={gender.id}>
                            {gender.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"text"}
                    type={"number"}
                    label={t("Phone Number")}
                    name={"phone_number"}
                    required
                    defaultValue={
                      type === "edit"
                        ? singleData && singleData.phone_number
                        : ""
                    }
                    onChange={(e) => getValue(e)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="Sensitive">{t("Sensitive")}</InputLabel>
                    <Select
                      labelId="Sensitive"
                      name="sensitive"
                      id="sensitive"
                      defaultValue={
                        type === "edit" && singleData
                          ? singleData.sensitive
                          : ""
                      }
                      required
                      label={"Sensitive"}
                      onChange={(e) => {
                        if (e.target.value == "no") {
                          setInp((prev) => {
                            return { ...prev, sensitive_note: "" };
                          });
                        }
                        getValue(e);
                      }}
                    >
                      <MenuItem value={"yes"}>{t("yes")}</MenuItem>
                      <MenuItem value={"no"}>{t("no")}</MenuItem>
                      <MenuItem value={"maybe"}>{t("maybe")}</MenuItem>
                      <MenuItem value={"dont_know"}>{t("dont know")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {inp.sensitive !== "no" && inp.sensitive !== "" && (
                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <TextField
                      id={"text"}
                      type={"text"}
                      label={t("Sensitive note")}
                      name={"sensitive_note"}
                      required
                      defaultValue={
                        type === "edit"
                          ? singleData && singleData.sensitive_note
                          : ""
                      }
                      onChange={(e) => getValue(e)}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              )}

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="access_method_id">
                      {t("How Did You Hear About Us")}
                    </InputLabel>
                    <Select
                      labelId="How Did You Hear About Us"
                      name="access_method_id"
                      id="access_method_id"
                      required
                      label={t("How Did You Hear About Us")}
                      onChange={(e) => getValue(e)}
                      defaultValue={
                        type === "edit" && singleData
                          ? singleData?.access_method_id
                          : ""
                      }
                    >
                      {loadingAccess ? (
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        accessMethods &&
                        accessMethods.map((access) => {
                          const translation = access.translations.find(
                            (t) => t.locale === i18n.language
                          );

                          return (
                            <MenuItem key={access.id} value={access.id}>
                              {translation ? translation.name : access.name}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {(inp?.access_method_id === "etc" ||
                singleData?.access_method_id === "etc") && (
                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <TextField
                      id={"text"}
                      type={"text"}
                      label={t("Here Us From")}
                      name={"clinic_note"}
                      required
                      defaultValue={
                        type === "edit"
                          ? singleData && singleData.clinic_note
                          : ""
                      }
                      onChange={(e) => getValue(e)}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              )}

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"text"}
                    type={"text"}
                    label={t("Address")}
                    name={"address"}
                    required
                    defaultValue={
                      type === "edit" ? singleData && singleData.address : ""
                    }
                    onChange={(e) => getValue(e)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="chronic_disease_id">
                      {t("Chronic Disease")}
                    </InputLabel>
                    <Select
                      labelId="Chronic Disease"
                      name="chronic_disease_id"
                      id="chronic_disease_id"
                      required
                      label="Chronic Disease"
                      onChange={(e) => getValue(e)}
                      defaultValue={
                        type === "edit" && singleData
                          ? singleData.chronic_disease_id
                          : ""
                      }
                    >
                      {loadingChronic ? (
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        chronicDiseases &&
                        chronicDiseases.map((disease) => {
                          const translation = disease.translations.find(
                            (t) => t.locale === i18n.language
                          );

                          return (
                            <MenuItem key={disease.id} value={disease.id}>
                              {translation ? translation.name : disease.name}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="patients_language_speaks">
                      {t("language speaks")}
                    </InputLabel>
                    <Select
                      labelId="language speaks"
                      name="patients_language_speaks"
                      id="patients_language_speaks"
                      required
                      label="language speaks"
                      onChange={(e) => getValue(e)}
                      defaultValue={
                        type === "edit" && singleData
                          ? singleData?.patients_language_speaks[0]?.id
                          : ""
                      }
                    >
                      {loadingLanguage ? (
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        languageSpeaks &&
                        languageSpeaks.map((language) => {
                          const translation = language.translations.find(
                            (t) => t.locale === i18n.language
                          );

                          return (
                            <MenuItem key={language.id} value={language.id}>
                              {translation ? translation.name : language.name}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel id="Date Of Birth">
                    {t("Date Of Birth")}:
                  </InputLabel>
                  <DatePicker
                    onChange={(newValue) => {
                      handleChangeDate(newValue);
                    }}
                    defaultValue={
                      type === "edit"
                        ? dayjs(singleData && singleData.date_of_birthday)
                        : null
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div
              className="btn-lest mt-5"
              style={{ margin: "40px !important", justifyContent: "center" }}
            >
              {isCreating || isUpdating ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span
                onClick={() => navigate(-1)}
                className="btn btn-danger-rgba"
              >
                <DoNotDisturbAltIcon color="#fff" /> {t("Back")}
              </span>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditCreateUser;
